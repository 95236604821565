<template>
  <div class="view">
    <UserPanel :title="'회원정보'">
      <div slot="header">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-button
              v-show="selectedUsers.length"
              type="warning"
              icon="el-icon-delete"
              @click="deleteUsers(selectedUserIDs)"
              >Delete
            </el-button>
          </el-col>

          <!-- <el-col :span="24" style="width: 100%"> -->
          <el-input
            v-model="keyword"
            prefix-icon="el-icon-search"
            placeholder="회원ID / 이름 / Email / 사번 검색"
            style="font-size: 78%; margin-right: 20px"
          ></el-input>
          <!-- </el-col> -->
        </el-row>
      </div>
      <el-table
        empty-text="검색한 정보가 없습니다"
        v-loading="loadingTable"
        element-loading-text="loading"
        @selection-change="handleSelectionChange"
        ref="table"
        :data="userList"
        style="width: 100%"
      >
        <!-- <el-table-column v-if="!$store.state.isMobile" prop="id" label="회원번호"></el-table-column> -->

        <el-table-column
          min-width="220"
          v-if="!$store.state.isMobile"
          prop="username"
          label="회원 ID"
        >
          <template slot-scope="scope">
            <div @click="openUserDialog(scope.row.id)">
              {{ scope.row.username }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          min-width="100"
          prop="real_name"
          ni
          label="이름"
        ></el-table-column>

        <el-table-column
          min-width="220"
          prop="email"
          label="Email"
        ></el-table-column>

        <el-table-column prop="school" label="사번">
          <template slot-scope="scope">
            {{ scope.row.school }}
          </template>
        </el-table-column>

        <el-table-column prop="github" label="소속코드">
          <template slot-scope="scope">
            {{ scope.row.github }}
          </template>
        </el-table-column>

        <!-- <el-table-column v-if="!$store.state.isMobile" prop="admin_type" label="분류">
          <template slot-scope="scope">
            {{ scope.row.admin_type }}
          </template>
        </el-table-column> -->

        <el-table-column
          min-width="100"
          v-if="!$store.state.isMobile"
          prop="create_time"
          label="가입날짜"
        >
          <template slot-scope="scope">
            {{ scope.row.create_time | utcToLocalSimpleOnlyDate }}
          </template>
        </el-table-column>

        <el-table-column
          min-width="180"
          v-if="!$store.state.isMobile"
          prop="last_login"
          label="최근 로그인"
        >
          <template slot-scope="scope">
            {{ scope.row.last_login | simpleLocaltime }}
          </template>
        </el-table-column>
        <el-table-column label="정보 조회/수정" width="250">
          <template slot-scope="{ row }">
            <icon-btn
              name="최근 푼 문제 "
              icon="search"
              @click.native="moveRecentlySolvedProblem(row)"
            ></icon-btn>
            <icon-btn
              name="level 별 완성도 조회하기"
              icon="check"
              @click.native="userProblemAchievment(row)"
            ></icon-btn>
            <icon-btn
              name="수정"
              icon="edit"
              @click.native="openUserDialog(row.id)"
            ></icon-btn>
            <icon-btn
              v-if="row.flag === 4"
              name="복구"
              icon="refresh"
              type="danger"
              @click.native="recoveredUsers([row.id])"
            ></icon-btn>
            <icon-btn
              v-else
              name="삭제"
              icon="delete"
              @click.native="hideUsers([row.id])"
            ></icon-btn>
          </template>
        </el-table-column>
      </el-table>
      <div class="panel-options">
        <el-pagination
          class="page"
          layout="prev, pager, next"
          @current-change="currentChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :total="total"
        >
        </el-pagination>
      </div>
    </UserPanel>

    <AdminPanel v-if="isSuperAdmin">
      <span slot="title"
        >회원등록
        <el-popover placement="right" trigger="hover">
          <p>csv파일에 제목줄 없이 ID, Password, Email를 라인별로 저장</p>
          <i
            slot="reference"
            class="el-icon-fa-question-circle import-user-icon"
          ></i>
        </el-popover>
      </span>
      <el-upload
        v-if="!uploadUsers.length"
        action=""
        :show-file-list="false"
        accept=".csv"
        :before-upload="handleUsersCSV"
      >
        <el-button size="small" icon="el-icon-upload" type="primary"
          >파일 가져오기</el-button
        >
      </el-upload>
      <template v-else>
        <el-table :data="uploadUsersPage">
          <el-table-column label="Username">
            <template slot-scope="{ row }">
              {{ row[0] }}
            </template>
          </el-table-column>
          <el-table-column label="Password">
            <template slot-scope="{ row }">
              {{ row[1] }}
            </template>
          </el-table-column>
          <el-table-column label="Email">
            <template slot-scope="{ row }">
              {{ row[2] }}
            </template>
          </el-table-column>
        </el-table>
        <div class="panel-options">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-upload"
            @click="handleUsersUpload"
            >Import All
          </el-button>
          <el-button
            type="warning"
            size="small"
            icon="el-icon-fa-undo"
            @click="handleResetData"
            >Reset Data
          </el-button>
          <el-pagination
            class="page"
            layout="prev, pager, next"
            :page-size="uploadUsersPageSize"
            :current-page.sync="uploadUsersCurrentPage"
            :total="uploadUsers.length"
          >
          </el-pagination>
        </div>
      </template>
    </AdminPanel>

    <AdminPanel v-if="isSuperAdmin && !$store.state.isMobile" :title="'회원생성'">
      <el-form :model="formGenerateUser" ref="formGenerateUser">
        <el-row type="flex" justify="space-between">
          <el-col :span="4">
            <el-form-item label="접두사" prop="prefix">
              <el-input
                v-model="formGenerateUser.prefix"
                placeholder="접두사"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="접미사" prop="suffix">
              <el-input
                v-model="formGenerateUser.suffix"
                placeholder="접미사"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="넘버링 시작값" prop="number_from" required>
              <el-input-number
                v-model="formGenerateUser.number_from"
                style="width: 100%"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="넘버링 끝값" prop="number_to" required>
              <el-input-number
                v-model="formGenerateUser.number_to"
                style="width: 100%"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="Password 길이" prop="password_length" required>
              <el-input
                v-model="formGenerateUser.password_length"
                placeholder="Password 길이"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item v-if="!$store.state.isMobile">
          <el-button
            type="primary"
            @click="generateUser"
            icon="el-icon-fa-users"
            :loading="loadingGenerate"
            >회원생성
          </el-button>
          <span
            class="userPreview"
            v-if="
              formGenerateUser.number_from &&
              formGenerateUser.number_to &&
              formGenerateUser.number_from <= formGenerateUser.number_to
            "
          >
            The usernames will be
            {{
              formGenerateUser.prefix +
              formGenerateUser.number_from +
              formGenerateUser.suffix
            }},
            <span
              v-if="
                formGenerateUser.number_from + 1 < formGenerateUser.number_to
              "
            >
              {{
                formGenerateUser.prefix +
                (formGenerateUser.number_from + 1) +
                formGenerateUser.suffix +
                "..."
              }}
            </span>
            <span
              v-if="
                formGenerateUser.number_from + 1 <= formGenerateUser.number_to
              "
            >
              {{
                formGenerateUser.prefix +
                formGenerateUser.number_to +
                formGenerateUser.suffix
              }}
            </span>
          </span>
        </el-form-item>
      </el-form>
    </AdminPanel>
    <!--대화 상자-->
    <el-dialog
      :class="$store.state.isMobile ? 'mobile-dialog' : ''"
      :width="$store.state.isMobile ? '100vw' : '70%'"
      top="50px"
      :title="'회원정보'"
      :visible.sync="showUserDialog"
      :close-on-click-modal="false"
    >
      <el-form :model="user" label-width="120px" label-position="left">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-form-item :label="'아이디'" required for="id">
              <el-input id="id" v-model="user.username"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-form-item :label="'이름'" required for="name">
              <el-input id="name" v-model="user.real_name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-form-item :label="'이메일 주소'" required for="email">
              <el-input id="email" v-model="user.email"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-form-item :label="'새 비밀번호'" for="password">
              <el-input id="password" v-model="user.password"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-form-item label="소속코드" for="belong">
              <el-input id="belong" v-model="user.github"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-form-item label="사번" for="pob">
              <el-input id="pob" v-model="user.school"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-form-item v-if="isAdminRole" :label="'회원정보 유형'" for="type">
              <el-select v-model="user.admin_type" id="type">
                <el-option
                    label="Regular User(회원)"
                    value="Regular User"
                ></el-option>
                <el-option label="Manager(매니저)" value="Manager"></el-option>
                <el-option v-if="isAdminRole"  label="Admin(강사)" value="Admin"></el-option>
                <el-option
                    v-if="isSuperAdmin"
                    label="Super Admin(시스템관리자)"
                    value="Super Admin"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-form-item label="그룹 권한">
              <template v-if="user && user['mincoding_auth']">
                <el-tag
                  style="margin-left: 3px"
                  size="mini"
                  :key="auth.id"
                  v-for="auth in user['mincoding_auth']['secret_code']"
                >
                  {{ auth["problem_step"]?.title }}
                </el-tag>
              </template>
              <template v-else> 그룹권한이 없습니다. </template>
            </el-form-item>
          </el-col>
          <el-col v-if="isSuperAdmin"  :xs="24" :sm="24" :md="12" :lg="12">
            <el-form-item label="유저 권한">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  id="auth"
                  v-for="auth in auth_list"
                  :label="auth.id"
                  :key="auth?.title"
                  >{{ auth?.title }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item :label="'비활성화'" for="disable">
              <el-switch id="disable" v-model="user.is_disabled"> </el-switch>
            </el-form-item>
          </el-col> -->

          
          <el-col :span="8">

            <el-form-item v-if="isSuperAdmin" label="유저 로그인하기 ">
              <icon-btn
                  v-if="isSuperAdmin"
                  name="로그인하기"
                  icon="key"
                  @click.native="loginUser(user.id)"
              ></icon-btn>
            </el-form-item>

            
            <el-form-item label="회원 삭제">
              <icon-btn
                name="완전 삭제"
                icon="delete"
                type="danger"
                @click.native="completelyDeleteUsers([user.id])"
              ></icon-btn>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="solveProblemLists.length">
            <el-form-item :label="'해결한 문제'">
              <el-tag
                style="margin-left: 3px"
                size="mini"
                v-for="solveProblemList in solveProblemLists"
                :key="solveProblemList"
              >
                {{ solveProblemList }}
              </el-tag>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <cancel @click.native="showUserDialog = false">Cancel</cancel>
        <save @click.native="saveUser()"></save>
      </span>
    </el-dialog>

    <!-- 달성률 -->
    <el-dialog
      :class="$store.state.isMobile ? 'mobile-dialog' : ''"
      title="달성률"
      :visible.sync="dialogVisible"
      :top="$store.state.isMobile ? '15px' : '15vh'"
      :width="$store.state.isMobile ? '100vw' : '50%'"
      :before-close="handleClose"
    >
      <el-tree
        :data="archiveData"
        :props="defaultProps"
        @node-click="handleNodeClick"
      ></el-tree>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">Cancel</el-button> -->
        <el-button type="primary" @click="dialogVisible = false"
          >확인</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import papa from "papaparse";
import api from "../../api.js";
import utils from "@/utils/utils";
import _ from "lodash";
import AdminPanel from "@admin/components/AdminPanel.vue";
import { mapGetters } from 'vuex';
export default {
  name: "User",
  components: {
    AdminPanel,
  },
  data() {
    return {
      // 한 페이지에 표시되는 사용자 수
      pageSize: 10,
      // 총 사용자 수
      total: 0,
      // 사용자 목록
      userList: [],
      uploadUsers: [],
      uploadUsersPage: [],
      uploadUsersCurrentPage: 1,
      uploadUsersPageSize: 15,
      dialogVisible: false,
      // 키워드 검색
      keyword: "",
      // 사용자 대화 상자 표시 여부
      showUserDialog: false,
      // 현재 사용자 모델
      user: {},
      loadingTable: false,
      loadingGenerate: false,
      // 현재 페이지 번호
      currentPage: this.$route.query.page ? +this.$route.query.page : 1,
      selectedUsers: [],
      formGenerateUser: {
        prefix: "",
        suffix: "",
        number_from: 0,
        number_to: 0,
        password_length: 8,
      },
      auth_list: [],
      checkList: [],
      solveProblemLists: [],
      problemStepLevelList: [],
      // 구조 step_level_list -> step_level -
      archiveData: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  async mounted() {
    const result = await api.getProblemStepList();
    if (result && result.data.data.data.length) {
      this.auth_list = result.data.data.data;
    }

    this.getUserList(this.currentPage);
  },
  methods: {
    handleSelectAll(v) {
      if (v.includes("all")) {
        this.user.auth = this.options;
        if (this.allFlag) {
          this.user.auth = v;
        }
        this.allFlag = true;
      } else {
        if (this.allFlag) {
          this.user.auth = [];
          this.allFlag = false;
        } else {
          this.user.auth = v;
        }
      }
    },
    // 페이지 번호 콜백 전환
    currentChange: _.debounce(function (page) {
      this.currentPage = page;
      this.getUserList(page);
      this.$router
        .push({
          path: `user`,
          query: { page: this.currentPage },
        })
        .catch(() => {});
      }, 400), 
    // 사용자 정보 수정을 위해 제출
    saveUser() {
      api
        .editUser(this.user)
        .then((res) => {
          // 업데이트 목록
          this.getUserList(this.currentPage);
        })
        .then(() => {
          this.showUserDialog = false;
        })
        .catch(() => {});

      // 넘겨야 하는값
      // user_id
      // checkList 넘기기
      // console.log(this.user);
      api.updateUserAuth({ user_id: this.user.id, code: this.checkList });
    },
    // 사용자 대화 상자 열기
    openUserDialog(id) {
      this.checkList = [];
      this.showUserDialog = true;
      api.getUser(id).then((res) => {
        this.user = res.data.data;
        api.getUserInfo(this.user.username).then((res) => {
          let ACMProblems = res.data.data.acm_problems_status.problems || {};
          let OIProblems = res.data.data.oi_problems_status.problems || {};
          // todo oi problems
          let ACProblems = [];
          for (let problems of [ACMProblems, OIProblems]) {
            Object.keys(problems).forEach((problemID) => {
              if (problems[problemID]["status"] === 0) {
                ACProblems.push(problems[problemID]["_id"]);
              }
            });
          }
          ACProblems.sort();
          this.solveProblemLists = ACProblems;
        });
        if (
          this.user["mincoding_auth"] &&
          this.user["mincoding_auth"]["secret_code"].length
        ) {
          this.user["mincoding_auth"]["secret_code"] = this.user[
            "mincoding_auth"
          ]["secret_code"].sort(function (a, b) {
            return a["problem_step"].order - b["problem_step"].order;
          });
        }

        if (this.user["user_auth"] && this.user["user_auth"].length) {
          this.checkList = this.user["user_auth"].map(
            (li) => li.problem_step.id
          );
        }

        // this.user.password = ''
        this.user.real_tfa = this.user.two_factor_auth;
      });
    },
    // 사용자 목록 가져 오기
    getUserList(page) {
      this.loadingTable = true;
      api
        .getUserList((page - 1) * this.pageSize, this.pageSize, this.keyword)
        .then(
          (res) => {
            this.loadingTable = false;
            this.total = res.data.data.total;
            this.userList = res.data.data.results;
          },
          (res) => {
            this.loadingTable = false;
          }
        );
    },
    deleteUsers(ids) {
      this.$confirm(
        "사용자를 삭제 하시겠습니까? 이 사용자가 생성 한 관련 리소스 (문제, 시험응시, 뉴스 등)도 삭제됩니다.",
        "확인",
        {
          type: "warning",
        }
      ).then(
        () => {
          api
            .deleteUsers(ids.join(","))
            .then((res) => {
              this.getUserList(this.currentPage);
            })
            .catch(() => {
              this.getUserList(this.currentPage);
            });
        },
        () => {}
      );
    },
    completelyDeleteUsers(ids) {
      this.$confirm(
        "사용자를 삭제 하시겠습니까? 이 사용자가 생성 한 관련 리소스 (문제, 시험응시, 뉴스 등)도 삭제됩니다.",
        "확인",
        {
          type: "warning",
        }
      ).then(
        () => {
          api
            .deleteUsers(ids.join(","))
            .then((res) => {
              this.showUserDialog = false;
              this.getUserList(this.currentPage);
            })
            .catch(() => {
              this.showUserDialog = false;
              this.getUserList(this.currentPage);
            });
        },
        () => {}
      );
    },
    hideUsers(ids) {
      this.$confirm("계정을 삭제(비활성화) 처리하시겠습니까?", "확인", {
        type: "warning",
      }).then(
        () => {
          api
            .hideUsers(ids.join(","))
            .then((res) => {
              this.getUserList(this.currentPage);
            })
            .catch(() => {
              this.getUserList(this.currentPage);
            });
        },
        () => {}
      );
    },
    recoveredUsers(ids) {
      this.$confirm("계정을 복구(활성화) 처리하시겠습니까?", "확인", {
        type: "warning",
      }).then(
        () => {
          api
            .recoveredUsers(ids.join(","))
            .then((res) => {
              this.getUserList(this.currentPage);
            })
            .catch(() => {
              this.getUserList(this.currentPage);
            });
        },
        () => {}
      );
    },
    handleSelectionChange(val) {
      this.selectedUsers = val;
    },
    generateUser() {
      this.$refs["formGenerateUser"].validate((valid) => {
        if (!valid) {
          this.$error("오류 필드를 확인하십시오");
          return;
        }
        this.loadingGenerate = true;
        let data = Object.assign({}, this.formGenerateUser);
        api
          .generateUser(data)
          .then((res) => {
            this.loadingGenerate = false;
            let url = "/admin/generate_user?file_id=" + res.data.data.file_id;
            utils.downloadFile(url).then(() => {
              this.$alert(
                "모든 사용자가 성공적으로 생성되고 사용자 시트가 디스크에 다운로드되었습니다.",
                "공지"
              );
            });
            this.getUserList(1);
          })
          .catch(() => {
            this.loadingGenerate = false;
          });
      });
    },
    handleUsersCSV(file) {
      papa.parse(file, {
        complete: (results) => {
          let data = results.data.filter((user) => {
            return user[0] && user[1] && user[2];
          });
          let delta = results.data.length - data.length;
          if (delta > 0) {
            this.$warning(delta + " 값이 비어있어 사용자가 필터링되었습니다.");
          }
          this.uploadUsersCurrentPage = 1;
          this.uploadUsers = data;
          this.uploadUsersPage = data.slice(0, this.uploadUsersPageSize);
        },
        error: (error) => {
          this.$error(error);
        },
      });
    },
    handleUsersUpload() {
      api
        .importUsers(this.uploadUsers)
        .then((res) => {
          this.getUserList(1);
          this.handleResetData();
        })
        .catch(() => {});
    },
    handleResetData() {
      this.uploadUsers = [];
    },
    page() {
      if (this.$route.query.page) {
        this.currentPage = +this.$route.query.page;
      } else {
        this.currentPage = 1;
      }
      this.getUserList(this.currentPage);
    },
    moveRecentlySolvedProblem(row) {
      window.open(`${window.location.origin}/status?username=${row.username}`);
      // this.$router.push(``)
      // status?myself=0&username=삼성&page=1
    },
    async userProblemAchievment(row) {
      this.dialogVisible = true;

      // {
      //     label: 'Level one 1',
      //     children: [{
      //       label: 'Level two 1-1',
      //       children: [{
      //         label: 'Level three 1-1-1'
      //       }]
      //     }]
      //   }

      // id를 인자로 넣어야한다
      // 그럼 먼저 리스트 호출하고 나서 진행시킨다
      // const {data} = await api.getProblemStep();
      // const problemStep = await api.getProblemStep();
      // console.log(problemStep['data']['data']['data']);

      // 팝업 띄우고 보여줘야한다
      const { data } = await api.getProblemStepAdmin();
      this.problemStepLevelList = data["data"]["data"];

      // 여기서 이제 필요한 기술 = 역참조
      // user의 id를 기반으로 profile2을 가져오는 기술이 필요하다.
      const acm = row["acm_problems_status"]["problems"];
      const oi = row["oi_problems_status"]["problems"];
      let acmList = null;
      let list = [];
      if (acm) {
        acmList = Object.values(acm)
          .filter((li) => li.status === 0)
          .map((li) => li._id);
        list = [...acmList];
      }
      let oiList = null;
      if (oi) {
        oiList =
          oi &&
          Object.values(oi)
            .filter((li) => li.status === 0)
            .map((li) => li._id);
        list = [...list, ...oiList];
      }

      if (list) {
        list = new Set(list);
        list = Array.from(list);
      }

      // console.log(list);

      // this.problemStepLevelList= this.problemStepLevelList &&
      //   this.problemStepLevelList.length &&
      //   this.problemStepLevelList.problem_step.length &&
      this.problemStepLevelList = this.problemStepLevelList.map((li) => {
        li.problem_step = li.problem_step.reduce((acc, cur) => {
          // 여기서 acc.prblem_step_level 과 list를 비교하면된다
          // list에 있는문제 / 총 문제 갯수 = 퍼센트를 나타낸다.
          let count = 0;
          // 겹치는거 만들기
          // console.log(cur.problem_step_level);
          if (cur && cur.problem_step_level && cur.problem_step_level.length) {
            for (let y = 0; y < cur.problem_step_level.length; y++) {
              // console.log(cur.problem_step_level[y].problem._id)
              for (let x = 0; x < list.length; x++) {
                if (cur.problem_step_level[y].problem._id === list[x]) {
                  count = count + 1;
                }
              }
            }
          }

          let percent = 0;
          if (cur.problem_step_level && cur.problem_step_level.length) {
            percent = ((count / cur.problem_step_level.length) * 100).toFixed(
              0
            );
          }
          // if(percent > 100){
          //   percent = 100;
          // }
          cur.percent = percent;

          acc.push(cur);
          // 채점하고 나서 유저 정보 업데이트를 하는 로직이 필요하다.
          return acc;
        }, []);
        return li;
      });
      // problem_step을 가져오고
      // step 별 list
      // 유저가 푼
      // console.log(this.problemStepLevelList);
      this.archiveData = this.problemStepLevelList.reduce((acc, cur) => {
        const data = {
          label: cur?.title,
          children:
            cur.problem_step &&
            cur.problem_step.length &&
            cur.problem_step.map((li) => {
              return { label: `${li?.title} 달성률: ${li.percent}%` };
            }),
        };
        acc.push(data);
        return acc;
      }, []);
    },
    handleClose() {
      this.problemStepLevelList = [];
      this.archiveData = [];
      this.dialogVisible = false;
    },
    handleNodeClick(data) {
      // console.log(data);
    },
    searchAuth(authType){
      this.authType = authType;
      this.getUserList()
    },
    async loginUser(id){

      const confirmResult = confirm("해당 유저에 로그인 하시겠습니까?")
      if(confirmResult){
        const data = {
          user_id:id
        }
        await api.adminLoginUser(data);
        this.$router.push("/")

      }
    }
  },
  computed: {
    ...mapGetters([ "isSuperAdmin", "hasProblemPermission", "isAdminRole"]),
    selectedUserIDs() {
      let ids = [];
      for (let user of this.selectedUsers) {
        ids.push(user.id);
      }
      return ids;
    },
  },
  watch: {
    keyword() {
      this.currentChange(1);
    },
    "user.admin_type"() {
      if (this.user.admin_type === "Super Admin") {
        this.user.problem_permission = "All";
      } else if (this.user.admin_type === "Regular User") {
        this.user.problem_permission = "None";
      }
    },
    uploadUsersCurrentPage(page) {
      this.uploadUsersPage = this.uploadUsers.slice(
        (page - 1) * this.uploadUsersPageSize,
        page * this.uploadUsersPageSize
      );
    },
    "$route.query": function () {
      this.page();
    },
  },
};
</script>

<style lang="less">
.import-user-icon {
  color: #555555;
  margin-left: 4px;
}
.userPreview {
  padding-left: 10px;
}
.notification {
  p {
    margin: 0;
    text-align: left;
  }
}
.mobile-dialog > .el-dialog {
  position: absolute !important;
  left: 5px !important;
}
</style>
